import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import CommonCard from "../card";
import config from "../../config";
import { Typography } from "@mui/material";

const homestays = [
  {
    id: 1,
    title: "Indus Valley Homestay",
    location: "Mukteshwar Nainital",
    imgSrc: `https://d2r3u9c7j9d9om.cloudfront.net/homestaycardtheshubhamgroup3.png`,
    link: "http://www.indusvalleyhomestay.com",
  },
];

function Project2_1_Homestay() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div data-aos-anchor-placement="top-center" data-aos="fade-up">
      <Typography
        className="text-2xl md:text-4xl p-3 text-center bg-cover"
        style={{
          letterSpacing: "0.05em",
          textShadow: "5px 5px 8px #767e8c",
          fontSize: "32px",
        }}
      >
        Homestay
      </Typography>

      <div className="flex flex-col">
        <section className="grid gap-5 m-auto p-3 md:gap-20">
          {homestays.map((homestay) => (
            <div className="w-full" key={homestay.id}>
              <CommonCard
                title={homestay.title}
                location={homestay.location}
                imgSrc={homestay.imgSrc}
                link={homestay.link}
              />
            </div>
          ))}
        </section>
      </div>
    </div>
  );
}

export default Project2_1_Homestay;
